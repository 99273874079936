<template>
  <action-dispatcher action="exam/get" class="view-exams">
    <div class="mb-3">
      <h3>Avaliações por módulo</h3>
      <small class="text-secondary">
        Aqui você encontrará todas as avaliações separadas por módulo
      </small>
    </div>
    <card
      v-for="(exam, index) in exams"
      :key="`exam-${index}`"
      class="row align-items-center mb-2"
    >
      <div class="col-lg-6 mb-2">
        <h5>
          {{ exam.title }}
        </h5>
      </div>
      <div class="col-lg-6">
        <div class="row align-items-center">
          <div class="col-lg-6 mb-3 mb-lg-0">
            <small v-if="getStatus(exam) !== 'Pendente'" class="text-dark">
              Prova realizada
            </small>
            <small
              v-else-if="$user.classInfo.exam_available_id != exam.module_id"
              class="text-dark"
            >
              Não disponível
            </small>

            <router-link
              v-if="
                getStatus(exam) == 'Pendente' &&
                  $user.classInfo.exam_available_id == exam.module_id
              "
              class="btn btn-primary"
              :to="{
                name: 'test-exam',
                params: {
                  class_id: $user.classInfo.id,
                  id: $user.classInfo.exam_available_id
                }
              }"
            >
              Responder
            </router-link>
          </div>
          <div class="col-lg-6">
            <div class="d-flex align-items-center">
              <span
                class="pb-1"
                :class="{ 'font-weight-bold': getStatus(exam) !== 'Pendente' }"
              >
                {{ getStatus(exam) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </card>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      user_answers: []
    };
  },
  mounted() {
    this.$store
      .dispatch("answers/getUserAnswers", this.$user.user.id)
      .then(data => {
        this.user_answers = data;
      });

    this.$store
      .dispatch("answers/getUserAnswers", this.$user.user.id)
      .then(data => {
        this.user_answers = data;
      });
  },
  computed: {
    ...mapState("exam", ["exams"])
  },
  methods: {
    getScore(customer_answer) {
      const length = customer_answer.answers.length;

      const correct_length = customer_answer.answers.filter(a => {
        const right_alternative = a.alternatives.find(at => at.is_right);
        return right_alternative && a.answer == right_alternative.id;
      }).length;

      return Math.ceil((correct_length * 100) / length);
    },
    getStatus(exam) {
      const exam_answer = this.user_answers.find(
        a => a.module == exam.module_id
      );

      if (exam_answer) {
        const score = this.getScore(exam_answer);
        const result = score > 50 ? "Aprovado" : "Reprovado";
        return `${result} - Nota: ${score}%`;
      }

      return "Pendente";
    }
  }
};
</script>

<style lang="scss" scoped>
.view-exams {
}
</style>
